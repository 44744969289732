import { Injectable , Inject} from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { DataService } from "../services/data.service";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  token : any ;
  constructor(private http: HttpClient, private _data : DataService, @Inject('TM_API_URL') public TM_API_URL: any){
		this.token = this._data.getCookiesByName('master_token');
  	}

	registerDeviceId(data){
		return this.http.post<any>(`${this.TM_API_URL}/transacto/v1/notification/subscribe`, data)
	}

	getUnseenNotificationList(payload: Object){
		return this.http.post<any>(`${this.TM_API_URL}/transacto/v1/notify/get`, payload).pipe(map((res) => res.data));
	}

	markAsSeenNotification(payload: Object){
		return this.http.post<any>(`${this.TM_API_URL}/transacto/v1/notify/mark-seen`, payload).pipe(map((res) => res.data));
	}

}
