import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { DataService } from "../services/data.service";
import { environment } from "../../environments/environment";

@Injectable()
export class ICOInterceptor implements HttpInterceptor {
  constructor(private _data: DataService) { }
  clientId: number;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {
      Accept: "application/json",
    };
    headersConfig["appsource"] = `DASHBOARD`;
    let access_token = this._data.getCookiesByName("master_token"); //multipart/form-data
    this._data.clientId.subscribe((res) => {
      this.clientId = res;
    });

    if (request.url.indexOf("oauth/token") > 0) {
      headersConfig["Content-Type"] = `application/x-www-form-urlencoded`;
      headersConfig[
        "Authorization"
      ] = ` Basic ${environment.masterAuthToken}`;
    }
    else if (request.url.indexOf("resetpasswordlink") > 0 || request.url.indexOf("resetpassword") > 0 || request.url.indexOf("setupaccount") > 0) {
      headersConfig["Content-Type"] = `application/json`;
    } else {
      headersConfig["Authorization"] = `Bearer ${access_token}`;
    }

    request = request.clone({
      setHeaders: headersConfig,
    });

    return next.handle(request);
  }
}
