import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { ResolutionService } from '../../pages/resolution/services/resolution.service';
import { fromEvent } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from '../../../app/pages/alerts/services/alerts.service';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-alerts-report',
  templateUrl: './alerts-report.component.html',
  styleUrls: ['./alerts-report.component.scss'],
})

export class AlertsReportComponent implements OnInit {
  transactionDetailsReport: any = null;
  @Input() item;
  @Output() close = new EventEmitter<any>();
  public bsModalRef: BsModalRef;
  senderComment: string = '';
  customerDetails: any;
  transactionDetails: any;
  timeRemaining: number = 0;
  customerCRR: any;
  history: any = [] = [];
  length: any;
  amlVal: number = 0;
  assigneList: any[] = []
  tab = "tab1";
  activityLog: boolean = true;
  CustomerDetailsReport: boolean = false;
  transactionRisk: any;
  sticky: boolean = false;
  windowSize: number = 0;
  uploadStatusFileName: string = '';
  uploadAssignmentFileName: string = '';
  theme: any;
  disableAction: any[] = ['RESOLVED', 'FAILED', 'CANCELLED'];
  actionsArray: any[] = [
    { name: 'Raise SAR', slug: 'sar', icons: '/assets/images/icons/raise-sar.svg' },
    { name: 'Resolve', slug: 'resolve', icons: '/assets/images/icons/green-tick.svg' },
    { name: 'Contact Remitter', slug: 'remitter', icons: '/assets/images/icons/contact-remitter.svg' },
    { name: 'Download as PDF', slug: 'download', kycIcons: true, icons: 'download' },
  ];
  ruleVoilatedList = {
    totalPage: 0,
    page: 1,
    size: 10,
    type: 'false',
    list: [],
    total: 0
  };
  reportObject: any = {
    sendViaEmail: false,
    downloadReport: true,
    emailId: null
  };
  total: number = 0;
  sum = 10;
  directionLinks: boolean = true;
  maxSize: number = 9;
  page: number = 1;
  attributesList: any[] = [
    { name: 'Alert ID', slug: 'alert-id', value: true, sort: true, sortAttr : 'guid' },
    { name: 'Rule Name', slug: 'rule-name', value: true, sort : false },
    { name: 'Customer ID', slug: 'customer-id', value: false, sort : false},
    { name: 'Customer Name', slug: 'customer-name', value: false, sort : false },
    { name: 'Transaction ID', slug: 'transaction-id', value: false, sort : true, sortAttr : 'transactionId' },
    { name: 'Created Date', slug: 'created-date', value: true, date: true, sort : true, sortAttr : 'createdDate' },
    { name: 'Last modified on', slug: 'last-modified-on', value: false, date: true, sort : true, sortAttr : 'lastModifiedDate' },
    { name: 'SLA', slug: 'sla', value: true, sort : true,  sortAttr : 'sla', numeric: true },
    { name: 'Time Left', slug: 'time-left', value: true, sort : false, numeric: true },
    { name: 'Status', slug: 'status', value: true, sort : true, sortAttr : 'userAlertStatus' },
    { name: 'Assigned To', slug: 'assigned-to', value: false, sort : false },
    { name: 'Severity', slug: 'severity', value: false, sort : true, sortAttr : 'alertCategory' },
  ];
  order: string = 'DESC';
  orderBy: string = 'createdDate';
  allAlerts: any[] = [];
  isCardView: boolean = false;
  tableHeaderCount: number = 0;
  @ViewChild('downloadReport') downloadReport: ElementRef;
  @ViewChild('reportGeneration') reportGeneration: ElementRef;
  public canvasWidth = 200
  public needleValue = 0
  public crrScore = 0;
  public crrLabel = '0'
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#17B18C', '#ED7D15', '#D84040'],
    arcDelimiters: [30, 50],
    rangeLabel: ['0', '100'],
    needleStartValue: 0,
  };
  crrData: any;
  customerGuid: any;
  isDisabledButton: boolean = true;
  public activityForm: FormGroup;
  public statusForm: FormGroup;
  public assigneeForm: FormGroup;
  sarData: any;
  actionTakenArray: any[] = [];
  activityArray: any[] = [];
  status: any[] = [];

  constructor(private modalService: NgbModal,
    private commonService: CommonService,
    private alertService: AlertsService,
    private modal: NgbModal,
    private data: DataService,
    private fb: FormBuilder,
    private service: ResolutionService) {
    this.statusForm = this.fb.group({
      status: this.fb.control('', Validators.required),
      description: this.fb.control('', Validators.required)
    }),
      this.assigneeForm = this.fb.group({
        assignee: this.fb.control('', Validators.required),
        description: this.fb.control('', Validators.required)
      });
    this.activityForm = this.fb.group({
      activity: this.fb.control('', Validators.required),
      actions: this.fb.control('', Validators.required)
    });
    this.windowSize = screen.width
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowSize = event.target.innerWidth;
  }

  ngOnInit(): void {
    // Hide Sidebar
    this.getTheme();
    this.getAssineeList();
    var element = document.getElementById("wrapper");
    let sidebarLogo = document.getElementById('sidebar-logo');
    let hideLogo = document.getElementById('hide-logo');
    element.classList.add("forced", "enlarged");
    if (sidebarLogo) sidebarLogo.style.marginLeft = '-12px';
    if (hideLogo) {
      hideLogo.style.display = 'none';
      this.data.sideBarHiddenVal(true);
    }
    // Hide Sidebar

    // Calculate total count
    this.tableHeaderCount = this.attributesList.reduce((counter, obj) => {
      if (obj?.value) counter += 1
      return counter;
    }, 0);

    if (sessionStorage.getItem('alertStatus')) {
      this.status = JSON.parse(sessionStorage.getItem('alertStatus'));
    }
    if (this.item) {
      this.getSarDetails(this.item?.guid);
      this.statusForm.patchValue({
        status: this.item?.userAlertStatus
      });
      this.assigneeForm.patchValue({
        assignee: this.item?.clientsUser?.clientsUserID
      });
      this.updateResolveText();
      if (this.item?.clientsUser) {
        const firstName = this.item?.clientsUser?.firstName || '';
        const middleName = this.item?.clientsUser?.middleName || '';
        const lastName = this.item?.clientsUser?.lastName || '';
        const clientsUserName = `${firstName} ${middleName} ${lastName}`
        this.item['assigneeFullName'] = clientsUserName.trim().replace(/ +/g, ' ');
      }
      console.log('rehan', this.item)
      if (this.item?.alert) {
        this.getCustomerDetails(this.item?.alert?.remitterId);
        this.getTransactionDetails(this.item?.alert?.transactionGuid);
        this.getHistory(this.item?.alert?.guid);
      }
      if (this.item?.transactionId) {
        this.getTransactionRiskDetails(this.item?.transactionId);
        this.getRuleListByTransactionId(this.ruleVoilatedList, this.item?.transactionId);
        this.getAlertsList(this.order, this.orderBy, this.item?.transactionId);
      }
      setInterval(() => {
        if (this.item?.createdDate) {
          const remaining = this.item?.createdDate + (this.item?.alert?.sla * 60000)
          const alertDate = new DatePipe('en-US').transform(new Date(remaining), 'EEEE, MMMM d, y, h:mm:ss a zzzz');
          const systemDate = new Date();
          var momentAlertDate = moment(alertDate);
          var momentSystemDate = moment(systemDate)
          const duration = moment.duration(momentAlertDate.diff(momentSystemDate));
          this.timeRemaining = duration.asMinutes()
        }
      }, 600)
    }
  }

  getAssineeList() {
    this.alertService.getAssigneeList().subscribe((res: any) => {
      this.assigneList = res?.data && res?.data?.content || [];
      if (this.assigneList?.length) {
        this.assigneList.forEach((item) => {
          const firstName = item?.firstName || '';
          const middleName = item?.middleName || '';
          const lastName = item?.lastName || '';
          const name = `${firstName} ${middleName} ${lastName}`;
          item['fullName'] = name.trim().replace(/ +/g, ' ');
        })
      }
    })
  }

  getTheme() {
    this.data.currentTheme.subscribe((res) => {
      if (res) {
        this.theme = res;
      } else {
        this.theme = localStorage.getItem("currentTheme");
      }
    })
  }

  getActionKey(item) {
    if (item?.slug == 'sar') {
      this.openModal(item?.sarGenerated ? this.downloadReport : this.reportGeneration);
    }
    else if (item?.slug == 'resolve') {
      this.statusForm.get('status').setValue('RESOLVED');
      this.statusForm.get('description').setValue('status resolved');
      this.updateStatus();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 6) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  getHistory(id) {
    this.service.getHistory(id).subscribe((res: any) => {
      this.history = [];
      var data = res?.data || [];
      for (var i = 1; data?.length > i; i++) {
        let formate1 = new DatePipe('en-Us').transform(data[i].createdDate, 'dd-MM-yyyy');
        let formate2 = new DatePipe('en-Us').transform(data[i - 1].createdDate, 'dd-MM-yyyy')
        if (formate1 == formate2) {
          data[i]['sameDate'] = 'yes'
        }
      }
      data.forEach(element => {
        if (element?.actionTakenByClientsUser) {
          const firstName = element?.actionTakenByClientsUser?.firstName || '';
          const middleName = element?.actionTakenByClientsUser?.middleName || '';
          const lastName = element?.actionTakenByClientsUser?.lastName || '';
          const fullName = `${firstName} ${middleName} ${lastName}`
          element['activityBy'] = fullName.trim().replace(/ +/g, ' ');
        }
        this.history.push(element);
      });
    })
  }
  getCustomerDetails(customerId: string) {
    let apiUrl;
    this.item?.alert?.transaction && this.item?.alert?.transaction?.remitterAccountNumber ? apiUrl = `transacto/v1/customer?accNum=${this.item?.alert?.transaction?.remitterAccountNumber}&cust-id=${customerId}` : apiUrl = `transacto/v1/customer?cust-id=${customerId}`;
    this.data.changeLoaderVisibility(true);
    this.commonService.getCustomerDetailByCustomerId(apiUrl).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe((res) => {
      if (res) {
        this.customerDetails = res;
        if (this.customerDetails?.riskCalculation && this.customerDetails?.riskCalculation?.amlResult) {
          const parseAmlData = JSON.parse(this.customerDetails?.riskCalculation?.amlResult);
          if (parseAmlData?.data && parseAmlData?.data?.content && parseAmlData?.data?.content?.length) {
            this.amlVal = parseAmlData?.data?.content[0]?.details?.length;
            this.customerGuid = parseAmlData?.data?.content[0]?.guid;
          }
          else {
            this.amlVal = 0;
          }
        }
        if (this.customerDetails?.riskCalculation && this.customerDetails?.riskCalculation?.crrResult) {
          this.customerCRR = JSON.parse(this.customerDetails?.riskCalculation?.crrResult);
          this.crrScore = this.customerCRR?.crrScore || 0;
          this.crrLabel = String(this.crrScore)
        }
      }
    });
  }

  getTransactionRiskDetails(transactionId: string) {
    this.commonService.getTransactionRiskDetails(transactionId).subscribe((res) => {
      this.transactionRisk = res;
    })
  }

  getTransactionDetails(guid: string) {
    this.commonService.getTransactionDetailsByGuid(guid).subscribe((res) => {
      if (res && res.content.length > 0) {
        this.transactionDetails = res['content'][0];
      }
    });
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit();
  }

  getRuleListByTransactionId(object: Object, transactionId) {
    const payload = {
      page: object['page'] - 1,
      size: object['size'],
      order: "DESC",
      orderBy: "createdDate",
      transactionId: transactionId,
    }
    this.commonService.getListOfAllRulesByTransactionId(payload, object['type']).subscribe((res) => {
      if (object['type'] == 'false') {
        this.ruleVoilatedList['list'] = res['content'] || [];
        this.ruleVoilatedList['total'] = res['totalElements'] || 0;
        this.ruleVoilatedList['totalPage'] = res['totalPages'] || 0;
      }
    });
  }

  handleIncrease(type: string) {
    if (type == 'false') {
      if (this.ruleVoilatedList['page'] < this.ruleVoilatedList['totalPage']) {
        this.ruleVoilatedList['page'] += 1;
        this.getRuleListByTransactionId(this.ruleVoilatedList, this.item?.transactionId);
      }
    }
  }

  handleDecrease(type: string) {
    if (type == 'false') {
      if (this.ruleVoilatedList['page'] > 1) {
        this.ruleVoilatedList['page'] -= 1;
        this.getRuleListByTransactionId(this.ruleVoilatedList, this.item?.transactionId);
      }
    }
  }


  // CRR Break Points

  crrBreakPoint(TemplateRef) {
    if (this.customerGuid && !this.crrData) {
      const payload = {
        guid: this.customerGuid
      }
      this.data.changeLoaderVisibility(true);
      this.commonService.getCrrBreakPoints(payload).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe((res) => {
        this.openModal(TemplateRef);
        if (res && res?.content && res?.content?.length) {
          this.crrData = res?.content[0]?.riskProfile?.riskFactor || [];
        }
      })
    }
    else if (this.crrData) {
      this.openModal(TemplateRef);
    }
  }

  getSarDetails(guid: string) {
    if (guid) {
      this.service.getSarReportInfo(guid).subscribe((res) => {
        this.sarData = res['data'];
        if (this.sarData?.isSarRaised) this.actionsArray = this.actionsArray.map((e) => ({ ...e, name: e?.slug == 'sar' ? 'Download SAR' : e?.name, sarGenerated: true }))
        if (this.sarData && this.sarData?.activityTypes && this.sarData?.activityTypes?.length) {
          for (let item of this.sarData?.activityTypes) {
            let obj = { name: item };
            this.activityArray.push(obj);
          }
          this.activityForm.patchValue({
            activity: this.sarData?.activityTypes[0]
          })
        }
        if (this.sarData && this.sarData?.actionTakens && this.sarData?.actionTakens?.length) {
          for (let item of this.sarData?.actionTakens) {
            let obj = { name: item };
            this.actionTakenArray.push(obj);
          }
          this.activityForm.patchValue({
            actions: this.sarData?.actionTakens[0]
          })
        }
      })
    }
  }

  checkDisability() {
    if (!this.reportObject?.sendViaEmail && !this.reportObject?.downloadReport) {
      this.isDisabledButton = false;
    }
    else if (this.reportObject?.sendViaEmail && this.reportObject?.downloadReport) {
      if (!this.reportObject?.emailId) {
        this.isDisabledButton = false;
      }
      else {
        const validation = this.validateEmail(this.reportObject?.emailId);
        validation == null ? this.isDisabledButton = false : this.isDisabledButton = true;
      }
    }
    else {
      if (this.reportObject?.downloadReport) {
        this.isDisabledButton = true;
      }
      else if (this.reportObject?.sendViaEmail) {
        if (!this.reportObject?.emailId) {
          this.isDisabledButton = false;
        }
        else {
          const validation = this.validateEmail(this.reportObject?.emailId);
          validation == null ? this.isDisabledButton = false : this.isDisabledButton = true;
        }
      }
    }
  }

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  updateSar() {
    const payload = {
      "actionTaken": this.activityForm.value.actions,
      "activityType": this.activityForm.value.activity,
      "userAlertGuid": this.item?.guid
    }
    this.data.changeLoaderVisibility(true);
    this.service.generateSarReport(payload).pipe(finalize(() => {
      this.data.changeLoaderVisibility(false);
    })).subscribe(() => {
      this.data.toastrShow(`Report generation process has been started`, 'success');
      this.getSarDetails(this.item?.guid);
      this.openModal(this.downloadReport);
    });
  }

  viewSarReport(reportGenerated: Boolean) {
    if (reportGenerated) {
      this.openModal(this.downloadReport);
    }
    else {
      this.data.toastrShow(`Report is not generated yet`, 'warning');
    }
  }

  downloadReportActions() {
    if (this.reportObject?.downloadReport) {
      this.downloadSarReport(this.item?.guid);
    }
    if (this.reportObject?.sendViaEmail && this.reportObject?.emailId) {
      this.sentReportViaMail(this.item?.guid);
    }
  }

  downloadSarReport(guid: string) {
    this.data.changeLoaderVisibility(true);
    this.service.downloadSarReport(guid).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe((res: any) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      let fileName = '';
      if (this.item?.alert?.transaction) fileName = `${this.item?.alert?.transaction?.remitterIdNumber}_${this.item?.alert?.transaction?.transactionId}`;
      FileSaver.saveAs(blob, `STR_${fileName}_report.pdf`);
    });
  }

  sentReportViaMail(guid: string) {
    const payload = {
      "email": this.reportObject?.emailId,
      "userAlertGuid": guid
    }
    this.data.changeLoaderVisibility(true);
    this.service.sentEmailReport(payload).pipe(finalize(() => {
      this.data.changeLoaderVisibility(false);
      this.data.toastrShow(`Report has been sent to ${this.reportObject?.emailId}`, 'success');
    })).subscribe();
  }

  removeAttachements() {
    this.uploadStatusFileName = '';
    this.uploadAssignmentFileName = '';
  }

  statusAttachementDetails(event) {
    this.uploadStatusFileName = event.target.files[0];
  }

  assignmentAttachementDetails(event) {
    this.uploadAssignmentFileName = event.target.files[0];
  }

  updateStatus() {
    let payload = {
      "userAlertStatus": this.statusForm.value.status,
      "comments": this.statusForm.value.description,
      "guid": this.item?.guid
    }
    const formData = new FormData();
    formData.append("file", this.uploadStatusFileName);
    formData.append("updateUserAlert", JSON.stringify(payload));
    this.data.changeLoaderVisibility(true);
    this.service.updateStatus(formData).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe((res: any) => {
      this.data.toastrShow(`Status has been updated from ${this.item.userAlertStatus} to ${res?.data?.userAlertStatus}`, 'success');
      this.item.userAlertStatus = res?.data?.userAlertStatus;
      this.getHistory(this.item?.alert?.guid);
      this.updateResolveText();
    })
  }

  updateAssignee() {
    let payload = {
      "assigned": this.assigneeForm.value.assignee,
      "comments": this.assigneeForm.value.description,
      "guid": this.item?.alert?.guid
    }
    const formData = new FormData();
    formData.append("file", this.uploadAssignmentFileName);
    formData.append("updateUserAlert", JSON.stringify(payload));
    this.data.changeLoaderVisibility(true);
    this.service.updateAssignee(formData).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe((res: any) => {
      this.getHistory(this.item?.alert?.guid);
    })
  }

  openModal(TemplateRef) {
    this.modal.open(TemplateRef, {
      ariaLabelledBy: "modal-basic-title",
      size: "md",
      backdrop: "static",
      modalDialogClass:
        this.theme === "theme-dark" ? "dark-modal" : "light-modal",
    });
  }

  updateResolveText() {
    if (this.item?.userAlertStatus == 'RESOLVED') this.actionsArray = this.actionsArray.map((e) => ({ ...e, name: e?.slug == 'resolve' ? 'Resolved' : e?.name }));
  }

  // Alerts Section

  toggleView() {
    if (!this.isCardView) {
      this.isCardView = true;
    }
    else {
      this.isCardView = false;
    }
  }

  getAlertsList(order, orderBy, transactionId: string) {
    const payload = {
      "order": order,
      "orderBy": orderBy,
      "page": this.page - 1,
      "size": this.sum,
      "transactionId": transactionId
    }
    this.data.changeLoaderVisibility(true);
    this.commonService.getRuleBreachListByTransactionId(payload).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe((res) => {
      this.allAlerts = res?.content || [];
      this.total = res['totalElements'];
      this.updateTableValue();
    })
  }

  orderByFilter(item){
    this.orderBy = item?.sortAttr;
    if(!item?.sortData){
      item.sortData = true;
      this.order = 'ASC';
    }
    else{
      item.sortData = false;
      this.order = 'DESC';
    }
    this.getAlertsList(this.order, this.orderBy, this.item?.transactionId);
  }

  pageChange(page){
    this.page=(page);
    this.getAlertsList(this.order, this.orderBy, this.item?.transactionId);
  }

  // Tbale header limit upto 7

  tableHeaderLimit(item) {
    if (item?.value) {
      this.tableHeaderCount += 1;
    }
    else {
      this.tableHeaderCount -= 1;
    }

    if (this.tableHeaderCount > 7) {
      item.value = false;
    }
    else {
      this.updateTableValue();
    }
  }

  showLimitPopup() {
    if (this.tableHeaderCount >= 7) this.data.toastrShow('You can select upto 7 attributes', 'info');
  }

  updateTableValue() {
    if (this.allAlerts?.length) {
      this.allAlerts.forEach((e) => {
        const rowData = [...this.attributesList.map((data) => {
          if (data.slug == 'alert-id') {
            data['rowData'] = e?.alert && e?.alert?.alertId;
          }
          else if (data.slug == 'customer-id') {
            data['rowData'] = e?.alert && e?.alert?.remitterId;
          }
          else if (data.slug == 'customer-name') {
            data['rowData'] = e?.alert && e?.alert?.transaction && e?.alert?.transaction?.remitterName;
          }
          else if (data.slug == 'rule-name') {
            data['rowData'] = e?.transactionRule && e?.transactionRule?.name;
          }
          else if (data.slug == 'transaction-id') {
            data['rowData'] = e?.alert && e?.alert?.transactionId;
          }
          else if (data.slug == 'created-date') {
            data['rowData'] = e?.alert && e?.alert?.createdDate;
          }
          else if (data.slug == 'last-modified-on') {
            data['rowData'] = e?.lastModifiedDate;
          }
          else if (data.slug == 'sla') {
            data['rowData'] = e?.alert && e?.alert?.sla;
          }
          else if (data.slug == 'status') {
            data['rowData'] = e?.alert && e?.alert?.alertStatus;
          }
          else if (data.slug == 'resolution') {
            data['rowData'] = e?.alert && e?.alert?.remitterId;
          }
          else if (data.slug == 'time-left') {
            if (e?.createdDate) {
              const remaining = e?.createdDate + (e?.alert?.sla * 60000)
              const alertDate = new DatePipe('en-US').transform(new Date(remaining), 'EEEE, MMMM d, y, h:mm:ss a zzzz');
              const systemDate = new Date();
              var momentAlertDate = moment(alertDate);
              var momentSystemDate = moment(systemDate)
              const duration = moment.duration(momentAlertDate.diff(momentSystemDate));
              data['rowData'] = duration.asMinutes().toFixed(2);
            }
          }
          else if (data.slug == 'assigned-to') {
            if (e?.clientsUser) {
              const firstName = e?.clientsUser?.firstName || '';
              const middleName = e?.clientsUser?.middleName || '';
              const lastName = e?.clientsUser?.lastName || '';
              const clientsUserName = `${firstName} ${middleName} ${lastName}`
              data['rowData'] = clientsUserName.trim().replace(/ +/g, ' ');
            }
            else {
              data['rowData'] = null;
            }

          }
          else if (data.slug == 'severity') {
            data['rowData'] = e?.alert && e?.alert?.alertCategory;
          }
          return {
            ...data
          }
        })
        ]
        e['attributeList'] = rowData;
      })
    }
  }

}
