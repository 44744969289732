const themeData = {
    defaultTheme: {
        id: 3,
        data: {
            "--card-heading-color": "#3D3D3D",
            "--page-heading-color": "#3D3D3D",
            "--hint-color": "#B4B4B4",
            "--user-input-color": "#3D3D3D",
            "--placeholder": "#B4B4B4",
            "--hint-small-color": "#B4B4B4",
            "--primary-highlight-box-bg-color": "#E4EFF2",
            "--secondary-highlight-box-bg-color": "#FFFFFF",
            "--primary-card-bg-color": "#C6DDE3",
            "--secondary-card-bg-color": "#FFFFFF",
            "--radio-button-bg-color": "#FFFFFF",
            "--inactive-radio-button-bg-color": "#F2F2F2",
            "--mapping-card": "#E9ECEF",
            "--line-map-dot": "#3D3D3D",
            "--ng-select-color": "#3D3D3D",
            "--ng-select-bg-color": "#FFFFFF",
            "--sidebar-active-color": "#41525D",
            "--sidebar-active-bg-color": "#FFFFFF",
            "--sidebar-menu-bg-color": "#41525D",
            "--kyc-stroke": "#B6D1DA",
            // Dark Mode New Theme SetUp
            "--dark-card-heading-color": "#E6E7F1",
            "--dark-page-heading-color": "#E6E7F1",
            "--dark-hint-color": "#BABCCC",
            "--dark-user-input-color": "#E6E7F1",
            "--dark-placeholder": "#BABCCC",
            "--dark-hint-small-color": "#BABCCC",
            "--dark-primary-highlight-box-bg-color": "#242D34",
            "--dark-secondary-highlight-box-bg-color": "#1D2328",
            "--dark-primary-card-bg-color": "#202930",
            "--dark-secondary-card-bg-color": "#1D2328",
            "--dark-radio-button-bg-color": "#1D2328",
            "--dark-inactive-radio-button-bg-color": "#2F3C45",
            "--dark-mapping-card": "#202930",
            "--dark-line-map-dot": "#E6E7F1",
            "--dark-ng-select-color": "#E6E7F1",
            "--dark-ng-select-bg-color": "#1D2328",
            "--dark-sidebar-active-color": "#00BDE3",
            "--dark-sidebar-active-bg-color": "#2F3C45",
            "--dark-sidebar-menu-bg-color": "#41525D",
            "--dark-kyc-stroke": "#32424E",
            // Common Color Code
            "--back-button-color": "#00BDE3",
            "--primary-button-color": "#FFFFFF",
            "--primary-button-bg-color": "#00BDE3",
            "--inactive-primary-button-color": "#727272",
            "--inactive-primary-button-bg-color": "#00000033",
            "--secondary-button-color": "#727272",
            "--chips-button-color": "#727272",
            "--primary-text-button-color": "#00BDE3",
            "--icon-button-color": "#00BDE3",
            "--icon-button-bg-color": "#00BDE3",
            "--text-success-color": "#17B18C",
            "--text-danger-color": "#D84040",
            "--text-warning-color": "#ED7D15",
            "--red-alert-color": "#D84040",
            "--red-alert-button-color": "#FFFFFF",
            "--red-alert-button-bg-color": "#D84040",
            "--special-button-color": "#FFFFFF",
            "--special-button-bg-color": "#FF531B",
            "--radio-button-color": "#00BDE3",
            "--inactive-radio-button-color": "#00000033",
            "--filter-cancel-color": "#727272",
            "--filter-cancel-bg-color": "#00000033",
            "--filter-button-color": "#00BDE3",
            "--filter-button-border": "#00000033",
            "--secondary-text-button-color": "#00BDE3",
            "--inactive-secondary-text-button-color": "#00000033",
            "--inactive-primary-text-button-color": "#00000033",
            "--toggle-button-color": "#00BDE3",
            "--inactive-toggle-button-color": "#00000033",
            "--special-text-color": "#FF531B",
            "--highlight-text-color": "#00BDE3",
            "--transparent-bg": "transparent",
        },
    },
    theme1: {
        id: 5,
        data: {
            // Light Mode New Theme SetUp
            "--card-heading-color": "#3D3D3D",
            "--page-heading-color": "#3D3D3D",
            "--hint-color": "#727272",
            "--user-input-color": "#3D3D3D",
            "--placeholder": "#727272",
            "--hint-small-color": "#727272",
            "--primary-highlight-box-bg-color": "#E0EFEF",
            "--secondary-highlight-box-bg-color": "#FFFFFF",
            "--primary-card-bg-color": "#C4DBDB",
            "--secondary-card-bg-color": "#FFFFFF",
            "--radio-button-bg-color": "#FFFFFF",
            "--inactive-radio-button-bg-color": "#727272",
            "--mapping-card": "#E9ECEF",
            "--line-map-dot": "#3D3D3D",
            "--ng-select-color": "#3D3D3D",
            "--ng-select-bg-color": "#FFFFFF",
            "--sidebar-active-color": "#059C9C",
            "--sidebar-active-bg-color": "#FFFFFF",
            "--sidebar-menu-bg-color": "#059C9C",
            "--kyc-stroke": "#B2D2D2",
            // Dark Mode New Theme SetUp
            "--dark-card-heading-color": "#E6E7F1",
            "--dark-page-heading-color": "#E6E7F1",
            "--dark-hint-color": "#BABCCC",
            "--dark-user-input-color": "#E6E7F1",
            "--dark-placeholder": "#BABCCC",
            "--dark-hint-small-color": "#BABCCC",
            "--dark-primary-highlight-box-bg-color": "#242D34",
            "--dark-secondary-highlight-box-bg-color": "#1D2328",
            "--dark-primary-card-bg-color": "#202930",
            "--dark-secondary-card-bg-color": "#1D2328",
            "--dark-radio-button-bg-color": "#1D2328",
            "--dark-inactive-radio-button-bg-color": "#2F3C45",
            "--dark-mapping-card": "#202930",
            "--dark-line-map-dot": "#E6E7F1",
            "--dark-ng-select-color": "#E6E7F1",
            "--dark-ng-select-bg-color": "#1D2328",
            "--dark-sidebar-active-color": "#059C9C",
            "--dark-sidebar-active-bg-color": "#00000066",
            "--dark-sidebar-menu-bg-color": "#059C9C",
            "--dark-kyc-stroke": "#32424E",
            // Common Color Code
            "--back-button-color": "#059C9C",
            "--primary-button-color": "#FFFFFF",
            "--primary-button-bg-color": "#059C9C",
            "--inactive-primary-button-color": "#727272",
            "--inactive-primary-button-bg-color": "#00000033",
            "--secondary-button-color": "#727272",
            "--chips-button-color": "#727272",
            "--primary-text-button-color": "#059C9C",
            "--icon-button-color": "#059C9C",
            "--icon-button-bg-color": "#059C9C",
            "--text-success-color": "#17B18C",
            "--text-danger-color": "#D84040",
            "--text-warning-color": "#ED7D15",
            "--red-alert-color": "#D84040",
            "--red-alert-button-color": "#FFFFFF",
            "--red-alert-button-bg-color": "#D84040",
            "--special-button-color": "#FFFFFF",
            "--special-button-bg-color": "#FF531B",
            "--radio-button-color": "#059C9C",
            "--inactive-radio-button-color": "#00000033",
            "--filter-cancel-color": "#727272",
            "--filter-cancel-bg-color": "#00000033",
            "--filter-button-color": "#059C9C",
            "--filter-button-border": "#00000033",
            "--secondary-text-button-color": "#059C9C",
            "--inactive-secondary-text-button-color": "#00000033",
            "--inactive-primary-text-button-color": "#00000033",
            "--toggle-button-color": "#059C9C",
            "--inactive-toggle-button-color": "#00000033",
            "--special-text-color": "#FF531B",
            "--highlight-text-color": "#059C9C",
            "--transparent-bg": "transparent",
        },
    },
    theme2: {
        id: 6,
        data: {
            // Light Mode New Theme SetUp
            "--card-heading-color": "#3D3D3D",
            "--page-heading-color": "#3D3D3D",
            "--hint-color": "#727272",
            "--user-input-color": "#3D3D3D",
            "--placeholder": "#727272",
            "--hint-small-color": "#727272",
            "--primary-highlight-box-bg-color": "#F9F3F2",
            "--secondary-highlight-box-bg-color": "#FFFFFF",
            "--primary-card-bg-color": "#EDE0DF",
            "--secondary-card-bg-color": "#FFFFFF",
            "--radio-button-bg-color": "#FFFFFF",
            "--inactive-radio-button-bg-color": "#727272",
            "--mapping-card": "#EDE0DF",
            "--line-map-dot": "#3D3D3D",
            "--ng-select-color": "#3D3D3D",
            "--ng-select-bg-color": "#FFFFFF",
            "--sidebar-active-color": "#F27E76",
            "--sidebar-active-bg-color": "#FFFFFF",
            "--sidebar-menu-bg-color": "#1572AA",
            "--kyc-stroke": "#EED9D7",
            // Dark Mode New Theme SetUp
            "--dark-card-heading-color": "#E6E7F1",
            "--dark-page-heading-color": "#E6E7F1",
            "--dark-hint-color": "#BABCCC",
            "--dark-user-input-color": "#E6E7F1",
            "--dark-placeholder": "#BABCCC",
            "--dark-hint-small-color": "#BABCCC",
            "--dark-primary-highlight-box-bg-color": "#242D34",
            "--dark-secondary-highlight-box-bg-color": "#1D2328",
            "--dark-primary-card-bg-color": "#202930",
            "--dark-secondary-card-bg-color": "#1D2328",
            "--dark-radio-button-bg-color": "#1D2328",
            "--dark-inactive-radio-button-bg-color": "#2F3C45",
            "--dark-mapping-card": "#202930",
            "--dark-line-map-dot": "#E6E7F1",
            "--dark-ng-select-color": "#E6E7F1",
            "--dark-ng-select-bg-color": "#1D2328",
            "--dark-sidebar-active-color": "#F27E76",
            "--dark-sidebar-active-bg-color": "#00000066",
            "--dark-sidebar-menu-bg-color": "#1572AA",
            "--dark-kyc-stroke": "#32424E",
            // Common Color Code
            "--back-button-color": "#F27E76",
            "--primary-button-color": "#FFFFFF",
            "--primary-button-bg-color": "#F27E76",
            "--inactive-primary-button-color": "#727272",
            "--inactive-primary-button-bg-color": "#00000033",
            "--secondary-button-color": "#727272",
            "--chips-button-color": "#727272",
            "--primary-text-button-color": "#F27E76",
            "--icon-button-color": "#F27E76",
            "--icon-button-bg-color": "#F27E76",
            "--text-success-color": "#17B18C",
            "--text-danger-color": "#D84040",
            "--text-warning-color": "#ED7D15",
            "--red-alert-color": "#D84040",
            "--red-alert-button-color": "#FFFFFF",
            "--red-alert-button-bg-color": "#D84040",
            "--special-button-color": "#FFFFFF",
            "--special-button-bg-color": "#FF531B",
            "--radio-button-color": "#F27E76",
            "--inactive-radio-button-color": "#00000033",
            "--filter-cancel-color": "#727272",
            "--filter-cancel-bg-color": "#00000033",
            "--filter-button-color": "#F27E76",
            "--filter-button-border": "#00000033",
            "--secondary-text-button-color": "#F27E76",
            "--inactive-secondary-text-button-color": "#00000033",
            "--inactive-primary-text-button-color": "#00000033",
            "--toggle-button-color": "#F27E76",
            "--inactive-toggle-button-color": "#00000033",
            "--special-text-color": "#FF531B",
            "--highlight-text-color": "#F27E76",
            "--transparent-bg": "transparent",
        },
    },
    LegacyTheme: {
        id: 1,
        data: {
            "--card-heading-color": "#31302E",
            "--page-heading-color": "#31302E",
            "--hint-color": "#6D6A67",
            "--user-input-color": "#31302E",
            "--placeholder": "#6D6A67",
            "--hint-small-color": "#6D6A67",
            "--primary-highlight-box-bg-color": "#E7E3DF",
            "--secondary-highlight-box-bg-color": "#F6EFE8",
            "--primary-card-bg-color": "#BAB4B4",
            "--secondary-card-bg-color": "#F6EFE8",
            "--radio-button-bg-color": "#F6EFE8",
            "--inactive-radio-button-bg-color": "#BAB4B4",
            "--mapping-card": "#BAB4B4",
            "--line-map-dot": "#31302E",
            "--ng-select-color": "#6D6A67",
            "--ng-select-bg-color": "#F6EFE8",
            "--sidebar-active-color": "#41525D",
            "--sidebar-active-bg-color": "#FFFFFF",
            "--sidebar-menu-bg-color": "#F6EFE8",
            "--kyc-stroke": "#C6BFB8",
            // Dark Mode New Theme SetUp
            "--dark-card-heading-color": "#EBECED",
            "--dark-page-heading-color": "#EBECED",
            "--dark-hint-color": "#98A6AD",
            "--dark-user-input-color": "#EBECED",
            "--dark-placeholder": "#98A6AD",
            "--dark-hint-small-color": "#98A6AD",
            "--dark-primary-highlight-box-bg-color": "#354650",
            "--dark-secondary-highlight-box-bg-color": "#2F3E47",
            "--dark-primary-card-bg-color": "#253138",
            "--dark-secondary-card-bg-color": "#2F3E47",
            "--dark-radio-button-bg-color": "#2F3E47",
            "--dark-inactive-radio-button-bg-color": "#2F3C45",
            "--dark-mapping-card": "#253138",
            "--dark-line-map-dot": "#E6E7F1",
            "--dark-ng-select-color": "#98A6AD",
            "--dark-ng-select-bg-color": "#2F3E47",
            "--dark-sidebar-active-color": "#F94F06",
            "--dark-sidebar-active-bg-color": "#253137",
            "--dark-sidebar-menu-bg-color": "#2F3E47",
            "--dark-kyc-stroke": "#475E6C",
            // Common Color Code
            "--back-button-color": "#007BFF",
            "--primary-button-color": "#FFFFFF",
            "--primary-button-bg-color": "#F94F06",
            "--inactive-primary-button-color": "#ffffff",
            "--inactive-primary-button-bg-color": "#00000033",
            "--secondary-button-color": "#007BFF",
            "--chips-button-color": "#727272",
            "--primary-text-button-color": "#F94F06",
            "--icon-button-color": "#007BFF",
            "--icon-button-bg-color": "#007BFF",
            "--text-success-color": "#17B18C",
            "--text-danger-color": "#D84040",
            "--text-warning-color": "#ED7D15",
            "--red-alert-color": "#D84040",
            "--red-alert-button-color": "#FFFFFF",
            "--red-alert-button-bg-color": "#D84040",
            "--special-button-color": "#FFFFFF",
            "--special-button-bg-color": "#F94F06",
            "--radio-button-color": "#F94F06",
            "--inactive-radio-button-color": "#00000033",
            "--filter-cancel-color": "#727272",
            "--filter-cancel-bg-color": "#00000033",
            "--filter-button-color": "#007BFF",
            "--filter-button-border": "#00000033",
            "--secondary-text-button-color": "007BFF",
            "--inactive-secondary-text-button-color": "#00000033",
            "--inactive-primary-text-button-color": "#00000033",
            "--toggle-button-color": "#F94F06",
            "--inactive-toggle-button-color": "#00000033",
            "--special-text-color": "#F94F06",
            "--highlight-text-color": "#007BFF",
            "--transparent-bg": "transparent",
        }
    }
};

// const p = []
// for (let [key, value] of Object.entries(themeData.defaultTheme.data)) {
//     p.push({ key, value, name: key.slice(2, key.length) })
// }
// console.log(JSON.stringify(p))
export default themeData;