import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { assetsMap } from './assets';
import { getColorVar } from  "../../../../assets/constants/common"

import { AddComponent } from './add.component';
import { AlertComponent } from './alert.component';
import { CrossComponent } from './cross.component';
import { CustomerComponent } from './customer.component';
import { DataIngestionComponent } from './data-ingestion.component';
import { DownloadComponent } from './download.component';
import { DropdownArrowComponent } from './dropdownArrow.component';
import { EditComponent } from './edit.component';
import { HomeComponent } from './home.component';
import { RedirectToComponent } from './redirect-to.component';
import { ReportComponent } from './report.component';
import { ResolutionComponent } from './resolution.component';
import { RulesComponent } from './rules.component';
import { SearchFilterComponent } from './searchFilter.component';
import { SettingsComponent } from './settings.component';
import { SubComponent } from './sub.component';
import { TransactionComponent } from './transaction.component';
import { ViewReportComponent } from './view-report.component';
import { YourDataComponent } from './your-data.component';

import {
  Add,Alert,Cross,Customer,DataIngestion,Download,DropdownArrow,Edit,Home,RedirectTo,Report,Resolution,Rules,SearchFilter,Settings,Sub,Transaction,ViewReport,YourData
} from '../assets';

const componentsMap = {
  [Add.name]: AddComponent,
    [Alert.name]: AlertComponent,
    [Cross.name]: CrossComponent,
    [Customer.name]: CustomerComponent,
    [DataIngestion.name]: DataIngestionComponent,
    [Download.name]: DownloadComponent,
    [DropdownArrow.name]: DropdownArrowComponent,
    [Edit.name]: EditComponent,
    [Home.name]: HomeComponent,
    [RedirectTo.name]: RedirectToComponent,
    [Report.name]: ReportComponent,
    [Resolution.name]: ResolutionComponent,
    [Rules.name]: RulesComponent,
    [SearchFilter.name]: SearchFilterComponent,
    [Settings.name]: SettingsComponent,
    [Sub.name]: SubComponent,
    [Transaction.name]: TransactionComponent,
    [ViewReport.name]: ViewReportComponent,
    [YourData.name]: YourDataComponent
};


@Component({
  selector: 'kyc-icon',
  template: `
    <svg
      #staticSvg
      [attr.width]="width"
      [attr.height]="height"
      [attr.viewBox]="getViewBox()"
      [attr.hidden]="iconFile.contextDefaults"
    ></svg>

    <div [hidden]="!iconFile.contextDefaults"><ng-template #dynSvg></ng-template></div>`,
  styles: [':host{ display: inline-block; position: relative; }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KycIconComponent implements OnInit, AfterViewInit {
  private static SEED = 0;

  @Input() public height: string | number = 0;
  @Input() public viewBox = '';
  @ViewChild('dynSvg', { read: ViewContainerRef, static: true }) public viewContainerRef;
  @ViewChild('staticSvg', {static: false }) public staticSvg: ElementRef;
  @Input() public width: string | number = 0;

  @Input() public set context(ctx) {
    this._context ={...{color:getColorVar((ctx || {}).color)},...ctx};
    if (this._iconComponent) {
      this._iconComponent.instance.context = ctx;
      this._ref.markForCheck();
    }
  }

  @Input() public set icon(icon: string) {
    this._createIcon(icon);
  }

  public get iconFile(): SVG2TSFile {
    return this._icon;
  }

  private _context: any;
  private _icon: SVG2TSFile;
  private _iconComponent: ComponentRef<SVG2TSDynamic>;
  private _isStaticIcon = false;
  private _initialized = false;

  constructor(
    private _ref: ChangeDetectorRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _renderer: Renderer2
  ) {}

  public getViewBox() {
    return this.viewBox !== ''
      ? this.viewBox
      : this._icon.viewBox
        ? [
            this._icon.viewBox.minx,
            this._icon.viewBox.miny,
            this._icon.viewBox.width,
            this._icon.viewBox.height
          ].join(' ')
        : [0, 0, this._icon.width, this._icon.height].join(' ');
  }

  public ngAfterViewInit() {
    if (this._isStaticIcon) {
      this._createStaticIcon();
      this._initialized = true;
    }
  }

  public ngOnInit() {
    if (this._context) {
      this.context = this._context;
    }
  }

  private _createDynamicIcon(icon: string) {
    const clazz = componentsMap[icon];
    const factory = this._componentFactoryResolver.resolveComponentFactory<any>(
      clazz
    );
    this.viewContainerRef.clear();
    this._iconComponent = this.viewContainerRef.createComponent(factory);
  }

  private _createIcon(icon: string) {
      this._icon = assetsMap[icon];
      if (!this._icon) {
        return;
      }
      if (this._icon && !this._icon['contextDefaults']) {
        this._isStaticIcon = true;
        this.width = this._icon.width;
        this.height = this._icon.height;
        if (this._initialized) {
          this._createStaticIcon();
          this._ref.detectChanges();
        }
      } else {
        this._createDynamicIcon(icon);
      }
  }

  private _createStaticIcon() {
    let svg = (this._icon.css
      ? `<style>${this._icon.css.replace(
        /.((?!})[\S]+?){{uuid}}/g,
        ''
      )}</style>`
      : '') + `<svg><g>${this._icon.svg}</g></svg>`;

    const currentSeed = KycIconComponent.SEED++;
    svg = svg.replace(/{{uuid}}/g, String(currentSeed));
    svg = this._resolveBasePath(svg);

    const inline = this._renderer.createElement('div');
    inline.innerHTML = svg;
    this._renderer.setAttribute(inline.firstChild as SVGElement, 'class', this._icon.svgHash + '-' + currentSeed);

    const myNode = this.staticSvg.nativeElement;
    if (myNode.firstChild) {
      this._renderer.removeChild(myNode.firstChild.parentNode, myNode.firstChild);
    }
    myNode.appendChild(inline.firstChild);
  }

  private _resolveBasePath(svg: string) {
    const baseUrl = window.location.href.replace(window.location.hash, '');

    return svg
      .replace(/xlink:href=["']#(.*?)["']/g, `xlink: href = "${baseUrl}#$1"`)
      .replace(/url\([']?#(.*?)[']?\)/g, `url(${ baseUrl }#$1)`);
  }

}
