import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-chart',
  templateUrl: './widget-chart.component.html',
  styleUrls: ['./widget-chart.component.scss']
})
export class WidgetChartComponent implements OnInit {
  widget = {}
  constructor() { }

  ngOnInit(): void {
  }

}
