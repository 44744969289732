import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-rule-query-preview',
  templateUrl: './rule-query-preview.component.html',
  styleUrls: ['./rule-query-preview.component.scss']
})
export class RuleQueryPreviewComponent implements OnChanges {
  @Input() rulePreviewArray: any;
  operatorsSymbol = {
    GTE: '>=',
    LTE: '<=',
    EQ: '=',
    LT: '<',
    GT: '>'
  }

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['rulePreviewArray']?.currentValue){
      this.rulePreviewArray = changes['rulePreviewArray']['currentValue'];
    }
    else{
      this.rulePreviewArray = this.rulePreviewArray;
    }
    this.getPreviewData();
  }

  getPreviewData(){
    this.rulePreviewArray?.groups.forEach((f) => {
      f.scenarios.forEach((item) => {
        let finalString = '( )';
        if(item?.selectedType == 'User_Input'){
          finalString = `( ${item?.field ? item?.field : ''} ${this.getOperatorSymbol(item?.operator)} ${item?.inputValue ? item?.inputValue : ''} )`
        }
        else{
          if(item?.field){
            finalString = `( ${item?.complexOperator ? item?.complexOperator : ''} ( 
              ${item?.field} ${this.getOperatorSymbol(item?.valueToCompareComparator)} ${item?.valueToCompare ? item?.valueToCompare : ''}
              ${item?.valueToCompare2 ? ('and ' + this.getOperatorSymbol(item?.valueToCompare2Comparator)) : ''} ${item?.valueToCompare2 ? item?.valueToCompare2 : ''}
              ${item?.isRangeOrInterval ? (item?.timeInterval ? ('and interval ' + item?.timeInterval) : '') : ((item?.startDate && item?.endDate) ? ('with Date Range ' + item?.startDate + ' to ' + item?.endDate) : '')}
              ${(item?.gteComplex && item?.lteComplex) ? ('Score below ' + item?.lteComplex + ' above ' +item?.gteComplex) : item?.lteComplex ? ('Score below ' + item?.lteComplex) : item?.gteComplex ? ('Score above ' + item?.gteComplex) : ''}
              ${item?.datasource?.length ? ('with value of ' + this.getDataSourceSplit(item?.datasource)) : ''}
              ) )`;
          }
          else{
            finalString = `( ${item?.complexOperator ? item?.complexOperator : ''} 
              ${this.getOperatorSymbol(item?.valueToCompareComparator)} ${item?.valueToCompare ? item?.valueToCompare : ''}
              ${item?.valueToCompare2 ? ('and ' + this.getOperatorSymbol(item?.valueToCompare2Comparator)) : ''} ${item?.valueToCompare2 ? item?.valueToCompare2 : ''}
              ${item?.isRangeOrInterval ? (item?.timeInterval ? ('and interval ' + item?.timeInterval) : '') : ((item?.startDate && item?.endDate) ? ('with Date Range ' + item?.startDate + ' to ' + item?.endDate) : '')}
              ${(item?.gteComplex && item?.lteComplex) ? ('Score below ' + item?.lteComplex + ' above ' +item?.gteComplex) : item?.lteComplex ? ('Score below ' + item?.lteComplex) : item?.gteComplex ? ('Score above ' + item?.gteComplex) : ''}
              ${item?.datasource?.length ? ('with value of ' + this.getDataSourceSplit(item?.datasource)) : ''}
              )`; 
          }
        }
        item['previewString'] = finalString;
      });
    })
  }

  getOperatorSymbol(operator){
    if(operator){
      return this.operatorsSymbol[operator];
    }
    return '';
  }

  getDataSourceSplit(datasource){
    if(datasource?.length){
      let joinedString;
      if (datasource?.length == 1) {
        joinedString = datasource[0];
      } 
      else if (datasource?.length == 2) {
        joinedString = datasource.join(" and ");
      } 
      else {
        const lastValue = datasource.pop();
        joinedString = datasource.join(", ") + ", and " + lastValue;
      }
      return joinedString;
    }
    return '';
  }

}
