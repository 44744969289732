import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { DataService } from "../services/data.service";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  token: any;

  constructor(
    private http: HttpClient,
    private _data: DataService,
    @Inject("API_URL") public API_URL: any,
    @Inject('TM_API_URL') public TM_API_URL: any,
    @Inject('MASTER_API_URL') public MASTER_API_URL: any
  ) {
    this.token = this._data.getCookiesByName("master_token");
    console.log(this.token);
  }

  doLogout(payload = {}) {
    return this.http.post<any>(`${this.MASTER_API_URL}/clientsuser/logout`, payload);
  }

  doLogin(accountLogin) {
    let data = new HttpParams()
      .set("username", accountLogin.username)
      .set("password", accountLogin.password)
      .set("grant_type", "password");
    console.log("this is the password", accountLogin.password)

    return this.http.post<any>(`${this.MASTER_API_URL}/oauth/token?username=${accountLogin.username}&password=${accountLogin.password}&grant_type=password`, null).pipe(
      map(res => {
        return res;
      })
    );
  }

  doRegister(accountRegister) {
    return this.http
      .post<any>(`${this.API_URL}/clients/register`, accountRegister)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  sendContactMsg(obj) {
    return this.http.post<any>(`${this.API_URL}/contactus`, obj).pipe(
      map(res => {
        return res;
      })
    );
  }

  sendResetLink(emailObj) {
    return this.http
      .post<any>(`${this.TM_API_URL}/transacto/v1/clientsuser/resetpasswordlink`, emailObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  changePassword(passObj) {
    return this.http
      .put<any>(`${this.API_URL}/clientsuser/changepassword`, passObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  verifyEmail(token) {
    return this.http
      .get<any>(`${this.API_URL}/clientsuser/verifyemail?token=${token}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  resetPassword(token, resetPassObj) {
    return this.http
      .put<any>(
        `${this.TM_API_URL}/transacto/v1/clientsuser/resetpassword?token=${token}`,
        resetPassObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  setUpAccount(token, resetPassObj) {
    return this.http
      .put<any>(`${this.TM_API_URL}/transacto/v1/clientsuser/setupaccount`, resetPassObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
}
