import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA
} from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

//Components
import { ClientConfigComponent } from "./components/client-config/client-config.component";

//Shared
import { NotificationsComponent } from "./shared/notifications/notifications.component";
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { KycLoaderComponent } from "./shared/kyc-loader/kyc-loader.component";
import { NotfoundComponent } from "./shared/notfound/notfound.component";
import { AmlLoaderComponent } from "./shared/aml-loader/aml-loader.component";
import { KycTourComponent } from "./shared/kyc-tour/kyc-tour.component";
import { VerifyComponent } from "./shared/verify/verify.component";

//Modules/Packages
import { CookieModule } from "ngx-cookie";
import { ToastrModule } from "ngx-toastr";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { ArchwizardModule } from "angular-archwizard";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CustomFormsModule } from "ng2-validation";
import { PrettyJsonModule } from "angular2-prettyjson";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { ImageCropperModule } from 'ngx-image-cropper';

// Firebase
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/database";
import "firebase/compat/storage";

//Services
import { DataService } from "./services/data.service";
import { MessagingService } from "./services/messaging.service";
import { LayoutService } from "./services/layout.service";
import { AuthService } from "./services/auth.service";
import { AppService } from "./services/app.service";
import { NotificationService } from "./services/notification.service";
import { PaymentService } from "./services/payment.service";
import { TourService } from "./services/tour.service";
import { TrackingService } from "./services/tracking.service";

//Interceptor
import { ICOInterceptor, ErrorInterceptor } from "./interceptors";

//Guards
import { AuthGuard } from "./guards/auth.guard";
import { DeGuard } from "./guards/deactivate.guard";
import { AccessGuard } from "./guards/access.guard";
import { PaymentGuard } from "./guards/payment.guard";
import { SaveData } from "./guards/save-data.guard";

// Pages
import { LoginComponent } from "./pages/auth/login/login.component";
import { RegisterComponent } from "./pages/auth/register/register.component";
import { PasswordComponent } from "./pages/auth/password/password.component";

// Directives Module
import { FormValidatorModule } from "./directives/form-validator/form-validator.module";
import { DndModule } from "./directives/dnd/dnd.module";
import { ShowImageModule } from "./directives/show-image/show-image.module";

// Pipe Module
import { PipesModule } from "./pipes/pipes.module";

// Environment
import { environment } from "../environments/environment";
import { SavaDataComponent } from "./guards/save-data-modal";
import { CommonModule } from "@angular/common";

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KycModalModule } from "./shared/kyc-modal/kyc-modal.module";
import { ComponentModuleModule } from "./components/component-module.module";
import { StatusModule } from "./directives/status/status.module";

const config = {
  apiKey: "AIzaSyBq-uSJu7bJ71hKpvgkx4hMJOBYzrc5IDs",
  authDomain: "kychub-61bdf.firebaseapp.com",
  projectId: "kychub-61bdf",
  storageBucket: "kychub-61bdf.appspot.com",
  messagingSenderId: "1098502164556",
  appId: "1:1098502164556:web:fa20304e1ce88463c6e4e9",
  measurementId: "G-Q8V7LBZLLX"
};

initializeApp(config);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    NotificationsComponent,
    LoginComponent,
    RegisterComponent,
    PasswordComponent,
    ClientConfigComponent,
    KycLoaderComponent,
    NotfoundComponent,
    AmlLoaderComponent,
    KycTourComponent,
    SavaDataComponent,
    VerifyComponent,
  ],
  imports: [
    CdkStepperModule,
    NgStepperModule,
    HttpClientModule,
    CommonModule,
    BrowserModule,
    KycModalModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ArchwizardModule,
    NgMultiSelectDropDownModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      closeButton: true
    }),
    ModalModule.forRoot(),
    CookieModule.forRoot(),
    FormValidatorModule,
    PipesModule,
    DndModule,
    ShowImageModule,
    PrettyJsonModule,
    NgxDaterangepickerMd.forRoot(),
    ImageCropperModule,
    InfiniteScrollModule,
    NgbModule,
    StatusModule,
    ComponentModuleModule,
  ],
  providers: [
    AuthGuard,
    DeGuard,
    AccessGuard,
    PaymentGuard,
    SaveData,
    DataService,
    MessagingService,
    PaymentService,
    TrackingService,
    TourService,
    AuthService,
    AppService,
    NotificationService,
    LayoutService,
    { provide: "Window", useValue: window },
    { provide: "API_URL", useValue: 'https://api.lm.kychub.co' }, // This is not in use currently, please avoid it
    { provide: "TM_API_URL", useValue: environment.API_URL },
    { provide: "MASTER_API_URL", useValue: environment.masterUrl },
    {
      provide: "User_Placeholder",
      useValue: "https://www.kychub.com/dashboard/assets/img/avatar/avatar.png"
    },
    { provide: HTTP_INTERCEPTORS, useClass: ICOInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
