import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class ManualOnboardingService {

  token: any;

  constructor(private _data: DataService, private http: HttpClient, @Inject('API_URL') public KYS_API_URL: any) {
    this.token = this._data.getCookiesByName('master_token');
    console.log(this.token);
  }

  getAllProvince(id){
    return this.http.get(`${this.KYS_API_URL}/provinces/country/${id}`).pipe(map((res: any) => res.data))
  }

  getAllInfo(id){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/${id}`).pipe(map((res: any) => res.data))
  }
  corpDocUpload(obj){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/upload`, obj).pipe(map((res: any) => res.data))
  }

  getDocumentUploadforCO(obj){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/manual-onboard/corporate/control-officer/upload`, obj).pipe(map((res: any) => res.data));
  }

  getIdDocUpload(obj) {
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/document/upload`, obj).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getDocName(country, type){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/available/docs/${country}?type=${type}`).pipe(map((res: any) => res.data))
  }
}
