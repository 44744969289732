import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShowImageModule } from "../directives/show-image/show-image.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgCircleProgressModule } from 'ng-circle-progress';
import {
  AdverseMediaArticlesComponent,
  StatusCompleteComponent,
  NoResultsFoundComponent,
  AlertsReportComponent,
  CustomerDetailComponent,
  TransactionDetailsComponent,
  WidgetsComponent,
  DataUnavailableComponent,
  KycSkeletonComponent,
  CreateWidgetComponent,
  IntervalWrapperComponent,
  NewAmlSummaryComponent,
  RiskScreeningGuageChartComponent,
  RiskScreeningTableComponent,
  RuleQueryPreviewComponent,
  WidgetChartComponent,
} from './index';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { GaugeChartModule } from 'angular-gauge-chart';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { StatusModule } from '../directives/status/status.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';
import { KycIconModule } from './kyc-icon/kyc-icon.module'
import { KycIconComponent } from './kyc-icon/components';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    StatusCompleteComponent,
    NoResultsFoundComponent,
    AlertsReportComponent,
    CustomerDetailComponent,
    TransactionDetailsComponent,
    WidgetsComponent,
    DataUnavailableComponent,
    KycSkeletonComponent,
    CreateWidgetComponent,
    IntervalWrapperComponent,
    NewAmlSummaryComponent,
    AdverseMediaArticlesComponent,
    RiskScreeningGuageChartComponent,
    RiskScreeningTableComponent,
    RuleQueryPreviewComponent,
    WidgetChartComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ShowImageModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    ImageCropperModule,
    InfiniteScrollModule,
    StatusModule,
    GaugeChartModule,
    NgxSkeletonLoaderModule,
    NgCircleProgressModule.forRoot({
      backgroundGradient: true,
      backgroundColor: '#ffffff',
      backgroundGradientStopColor: '#c0c0c0',
      backgroundPadding: -10,
      radius: 60,
      maxPercent: 100,
      outerStrokeWidth: 10,
      outerStrokeColor: '#61A9DC',
      innerStrokeWidth: 0,
      subtitleColor: '#444444',
      showBackground: false,
    }),
    NgxChartsModule,
    ChartsModule,
    NgxPaginationModule,
    NgSelectModule,
    ColorPickerModule,
    KycIconModule,
    SlickCarouselModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [
    StatusCompleteComponent,
    NoResultsFoundComponent,
    AlertsReportComponent,
    CustomerDetailComponent,
    TransactionDetailsComponent,
    WidgetsComponent,
    DataUnavailableComponent,
    KycSkeletonComponent,
    KycIconComponent,
    NewAmlSummaryComponent,
    AdverseMediaArticlesComponent,
    RiskScreeningGuageChartComponent,
    RiskScreeningTableComponent,
    RuleQueryPreviewComponent,
    WidgetChartComponent
  ],
})
export class ComponentModuleModule { }
