import { Pipe, PipeTransform } from "@angular/core";
import { AppService } from "../services/app.service";

@Pipe({
  name: "CustomerDetailsPipe"
})
export class CustomerDetailsPipe implements PipeTransform {
    constructor(private _app: AppService){}

    async transform(value: string) {
        const res: any = await this._app.getCustomerAlertDetailsById(value).toPromise();
        return res?.data;
    }
}
