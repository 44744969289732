import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private http: HttpClient, @Inject('TM_API_URL') public TM_API_URL: any) { }

  getAlertList(payload) {
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/filter`,payload).pipe(map((res) => res['data']));
  }
  getAlertWithFilter(type,payload){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/filter-by-category/${type}`,payload)
  }
  markAsSeen(id){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/status/seen/${id}`,null)
  }
  assignTheDataOfAlert(payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/update-multiple/assigned-to`, payload)
  }
  getAssigneeList(){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/clientsuser/all?page=0&size=50&sortOrder=asc`)
  }
  getRelatedAlerts(payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/similar`, payload).pipe(map((res) => res['data']));
  }
  changeStatusOfAlert(payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/update-multiple/status`, payload)
  }
}
