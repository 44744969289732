export const settingRoutes = {
    POLICY: 'policy',
    POLICY_VIEW: 'policy/view',
    POLICY_CREATE: 'policy/id',
    POLICY_EDIT: 'policy/id',
    ROLE: 'role',
    ROLE_VIEW: 'role/view',
    ROLE_CREATE: 'role/id',
    ROLE_EDIT: 'role/id',
    USER: 'user',
    USER_VIEW: 'user/view',
    USER_CREATE: 'user/id',
    USER_EDIT: 'user/id',
    USER_GROUP_CREATE: 'user/group/id',
    USER_GROUP_EDIT: 'user/group/id',
    PROFILE: 'profile',
    GENERAL: 'general',
    VISUAL: 'visuals'
}
export const generateTextToColor = (string, s = 60, l = 30) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let h = hash % 360;
    // HSL format
    // return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };
    // HEX format
    return `#${f(0)}${f(8)}${f(4)}`;

}


export const getColorVar = (name) => {
    switch (name) {
        case 'primary':
            return "var(--primary-button-bg-color)"

        case 'secondary':
            return "var(--secondary-button-bg-color)"

        default:
            return "var(--primary-button-bg-color)"
    }

}
export const searchFilterByStr = (searchQuery, array, objectKey = null) => {
        const res = array.filter(e => e.includes(searchQuery.toLowerCase()))
        console.log('fil', searchQuery, searchQuery.trim(), res)
        return res
    }
    // return array.filter(d => {
    // let data = objectKey ? d[objectKey] : d //Incase If It's Array Of Objects.
    // let dataWords = typeof data == "string" && data?.split(" ")?.map(b => b && b.toLowerCase().trim()).filter(b => b)
    // let searchWords = typeof searchQuery == "string" && searchQuery?.split(" ").map(b => b && b.toLowerCase().trim()).filter(b => b)
    // let matchingWords = searchWords.filter(word => dataWords.includes(word))
    // console.log('fil',dataWords,searchWords,matchingWords)
    // return matchingWords.length