import { Component, HostListener, Input, OnInit, Output } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from "../../services/data.service";
import { EventEmitter } from "@angular/core";
import { AppService } from "../../services/app.service";
import { NotificationService } from "../../services/notification.service";
import { MessagingService } from "../../services/messaging.service";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  showTutorial: boolean = false;
  themeVal:any;
  userName: string;
  welcome: boolean=this.router.url === '/home'?true:false;
  notificationsData: any[] = [];
  notificationCount: number = 0;
  isLoading: boolean = false;
  sum = 10;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  start: number = 0;
  downloading: boolean = false;
  constructor(
    private router: Router,
    private _data: DataService,
    public route: ActivatedRoute,
    public app: AppService,
    private notiService: NotificationService,
    private messagingService: MessagingService
  ) {    
    router.events.subscribe(val => {
      if (router.url === '/home') {
        this.welcome=true
        if (screen.width < 1200) {
          this.showTutorial = false;
        }
        else {
          this.showTutorial = true;
        }
      }
      else {
        this.showTutorial = false;
        this.welcome=false
      }
    })
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    let dropdown = document.getElementById('dropdownMenu12');
    if (target.classList.contains('exclude-click')) {
      dropdown.style.display = 'block';
    }
    else{
      dropdown.style.display = 'none';
    }
  }


  @HostListener("window:resize", ["$event"])
  public onResize(event) {
    if (event.target.innerWidth < 1200) {
      this.showTutorial = false;
    }
    else {
      if (this.router.url === '/home') {
        this.showTutorial = true;
      }
    }
  }

  ngOnInit() {
    this.app.userName.subscribe(res=>{
      this.userName=res;
    })
    if (localStorage.getItem('currentTheme')== 'theme-light') {
      this.themeVal="Dark Mode";

    } else {
      this.themeVal="Light Mode";
    }
    this.getUnseenNotificationList(this.start, this.sum);
  }

  refreshNotification(type: string){
    if(type == 'refresh'){
      this.downloading = false;
      this.notificationsData = [];
      this.start = 0;
      this.getUnseenNotificationList(this.start, this.sum);
    }
    else{
      let dropdown = document.getElementById('dropdownMenu12');
      dropdown.style.display = 'block';
      this.messagingService.currentMessage.subscribe((val) => {
        if(Number(val?.data?.count) > this.notificationCount){
          this.notificationCount = val?.data?.count;
          this.notificationsData = [];
          this.start = 0;
          this.getUnseenNotificationList(this.start, this.sum);
        }
      });
    }
  }

  getUnseenNotificationList(page, size){
    this.isLoading = true;
    const payload = {
      "order": "DESC",
      "orderBy": "createdDate",
      "page": page,
      "size": size,
      "viewStatus": 'UNSEEN'
    }
    this.notiService.getUnseenNotificationList(payload).pipe(finalize(() => this.isLoading = false)).subscribe((res) => {
      if(res?.content?.length){
        res?.content.forEach(element => {
          this.notificationsData.push(element)
        });
      }
      this.notificationCount = res?.totalElements;
    })
  }

  onScrollDown() {
    this.downloading = true;
    this.start += 1;
    this.getUnseenNotificationList(this.start, this.sum);
    this.direction = "down";
  }

  async markAsSeenNotification(id){
    const payload = {
      notificationIds : [id]
    }
    const res = await this.notiService.markAsSeenNotification(payload).toPromise();
    this.notificationsData = [];
    this.start = 0;
    this.getUnseenNotificationList(this.start, this.sum);
  }

  goToDetails(item){
    if(item?.notificationType?.toUpperCase() == 'DATASET'){
      this.router.navigateByUrl(item?.urlPath);
      this.markAsSeenNotification(item?.id);
    }
  }
  
  @Input() public currentTheme: string;
  
  @Output() changedTheme: EventEmitter<any> = new EventEmitter<any>();
  themeChange: boolean = false;
  themeToggle() {
    if (this.currentTheme == 'theme-light') {
      this.themeVal="Light Mode";
      localStorage.setItem('currentTheme', 'theme-dark');
      this.changedTheme.emit('theme-dark');
      this.app.setCurrentTheme('theme-dark');
      this._data.currentThemeVal('theme-dark')

    } else if (this.currentTheme == 'theme-dark') {
      this.themeVal="Dark Mode";
      this.changedTheme.emit('theme-light');
      localStorage.setItem('currentTheme', 'theme-light');
      this.app.setCurrentTheme('theme-light');
      this._data.currentThemeVal('theme-light')
    }
  }

  swtichToPlatform(dashboard){
    window.open(`https://${dashboard}.kychub.com/home`);
  }

  toggleTutorial() {
    this.app.toggleTutorial(true);
  }
}
