// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: "https://stage.api.transacto.kychub.com",
  masterAuthToken: 'N2VmMzdkMGUxZjMzYWU2ZTkwMzdhZmYwY2IyZTNhMTgzMjE3OTYzOTNjODNlMDhiMmYwNTM3YzE5YjY5NjI2NjoxYmQ2Y2Q1MTdhMDUwZGEyMWY4MDQ1Njk0MWFiMTU0NjU2MDhlZjFmMTNmYWQ1MzA5NTc5ZDg5NGFmYTc3OWFm',
  masterUrl: "https://api.app.kychub.com",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
