import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./pages/auth/login/login.component";
import { RegisterComponent } from "./pages/auth/register/register.component";
import { PasswordComponent } from "./pages/auth/password/password.component";
// import { ClientConfigComponent } from "./components/client-config/client-config.component";

// import { PaymentComponent } from "./pages/payment/payment.component";

import { NotfoundComponent } from "./shared/notfound/notfound.component";
import { VerifyComponent } from "./shared/verify/verify.component";

//Guards
import { AuthGuard } from "./guards/auth.guard";
import { DeGuard } from "./guards/deactivate.guard";
import { AccessGuard } from "./guards/access.guard";
// import { PaymentGuard } from "./guards/payment.guard";
const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "home",
    pathMatch: "full",
    loadChildren: () => import("./pages/dashboard/dashboard.module").then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: "data-ingestion",
    loadChildren: () => import("./pages/data-ingestion/data-ingestion.module").then(m => m.DataIngestionModule),
    canActivate: [AuthGuard]
  },
  {
    path: "your-data",
    loadChildren: () => import("./pages/your-data/your-data.module").then(m => m.YourDataModule),
    canActivate: [AuthGuard]
  },
  {
    path: "resolution",
    loadChildren: () => import("./pages/resolution/resolution.module").then(m => m.ResolutionModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'alerts',
    loadChildren: () => import("./pages/alerts/alerts.module").then(m => m.AlertsModule),
  },
  {
    path: 'transactions',
    loadChildren: () => import("./pages/transactions/transactions.module").then(m => m.TransactionsModule),
  },
  {
    path: "rules",
    loadChildren: () => import("./pages/rules/rules.module").then(m => m.RulesModule),
    canActivate: [AuthGuard]
  },
  {
    path: "notifications",
    loadChildren: () => import( "./pages/notification/notification.module").then(m => m.NotificationModule),
    canActivate: [AuthGuard]
  },
  {
    path: "settings",
    loadChildren: () => import( "./pages/settings/settings.module").then(m => m.SettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: "invoices",
    loadChildren: () => import( "./pages/invoices/invoices.module").then(m => m.InvoicesModule),
    canActivate: [AuthGuard]
  },
  {
    path: "customers",
    loadChildren: () => import( "./pages/customer/customer.module").then(m => m.CustomerModule),
    canActivate: [AuthGuard]
  },



  {
    path: "access-denied",
    loadChildren: () => import("./pages/access-denied/access-denied.module").then(m => m.AccessDeniedModule),
    canActivate: [AuthGuard]
  },
  
  {
    path: "faq",
    loadChildren: () => import( "./pages/faq/faq.module").then(m => m.FaqModule),
    canActivate: [AuthGuard]
  },

  {
    // path: "services",
    path: "subscribe/payment",
    loadChildren: () => import( "./pages/payment/payment.module").then(m => m.PaymentModule),
    canActivate: [DeGuard]
  },


  {
    path: "login",
    component: LoginComponent,
    canActivate: [DeGuard]
  },
  {
    path: "register",
    component: RegisterComponent,
    canActivate: [DeGuard]
  },
  {
    path: "test",
    loadChildren: () => import( "./shared/test/test.module").then(m => m.TestModule),
    canActivate: [AuthGuard]
  },
  {
    path: "theming",
    loadChildren: () => import( "./pages/theming/theming.module").then(m => m.ThemingModule),
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: "customize",
    loadChildren: () => import( "./pages/customize/customize.module").then(m => m.CustomizeModule),
    canActivate: [AuthGuard]
  },
  //Password Related
  {
    path: "reset-password",
    component: PasswordComponent,
    canActivate: [DeGuard]
  },
  {
    path: "setup-account",
    component: PasswordComponent,
  },
  {
    path: "forgot-password",
    component: PasswordComponent,
    canActivate: [DeGuard]
  },
  {
    path: "verify-email",
    component: PasswordComponent,
    canActivate: [DeGuard]
  },

  {
    path: "change-password",
    component: PasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "404",
    component: NotfoundComponent
  },
  {
    path: "verify",
    component: VerifyComponent,
  },
  {
    path: "**",
    redirectTo: "/404"

    // children: [
    //   {path: '', redirectTo: '404' , pathMatch : "full"  },
    //   {path: '404', component: NotfoundComponent  }    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      useHash: false,
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
